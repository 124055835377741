exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-manuals-effective-communication-md": () => import("./../../../src/pages/manuals/effective-communication.md" /* webpackChunkName: "component---src-pages-manuals-effective-communication-md" */),
  "component---src-pages-manuals-index-mdx": () => import("./../../../src/pages/manuals/index.mdx" /* webpackChunkName: "component---src-pages-manuals-index-mdx" */),
  "component---src-pages-manuals-medicine-wheel-mdx": () => import("./../../../src/pages/manuals/medicine-wheel.mdx" /* webpackChunkName: "component---src-pages-manuals-medicine-wheel-mdx" */),
  "component---src-pages-manuals-milestones-mdx": () => import("./../../../src/pages/manuals/milestones.mdx" /* webpackChunkName: "component---src-pages-manuals-milestones-mdx" */),
  "component---src-pages-manuals-open-source-operations-mdx": () => import("./../../../src/pages/manuals/open-source-operations.mdx" /* webpackChunkName: "component---src-pages-manuals-open-source-operations-mdx" */),
  "component---src-pages-manuals-roles-mdx": () => import("./../../../src/pages/manuals/roles.mdx" /* webpackChunkName: "component---src-pages-manuals-roles-mdx" */),
  "component---src-pages-manuals-standards-of-conduct-mdx": () => import("./../../../src/pages/manuals/standards-of-conduct.mdx" /* webpackChunkName: "component---src-pages-manuals-standards-of-conduct-mdx" */),
  "component---src-pages-manuals-toast-mdx": () => import("./../../../src/pages/manuals/toast.mdx" /* webpackChunkName: "component---src-pages-manuals-toast-mdx" */),
  "component---src-pages-manuals-tock-mdx": () => import("./../../../src/pages/manuals/tock.mdx" /* webpackChunkName: "component---src-pages-manuals-tock-mdx" */),
  "component---src-pages-manuals-trello-mdx": () => import("./../../../src/pages/manuals/trello.mdx" /* webpackChunkName: "component---src-pages-manuals-trello-mdx" */),
  "component---src-pages-manuals-wine-program-mdx": () => import("./../../../src/pages/manuals/wine-program.mdx" /* webpackChunkName: "component---src-pages-manuals-wine-program-mdx" */),
  "component---src-pages-recipes-gazpacho-md": () => import("./../../../src/pages/recipes/gazpacho.md" /* webpackChunkName: "component---src-pages-recipes-gazpacho-md" */),
  "component---src-pages-recipes-gozleme-md": () => import("./../../../src/pages/recipes/gozleme.md" /* webpackChunkName: "component---src-pages-recipes-gozleme-md" */),
  "component---src-pages-recipes-index-md": () => import("./../../../src/pages/recipes/index.md" /* webpackChunkName: "component---src-pages-recipes-index-md" */)
}

